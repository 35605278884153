import React, {useContext, useEffect, useState} from 'react';

import {List, ListItem, Radio, Typography, Upload, Button, Select, Option, Input} from 'spenda-ui-react';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import {IAccounts} from '../../model/accounts/Accounts';
import useAccountAPI from '../../services/useAccountAPI';
import {FileType} from '../../components/dialog/QuoteAttachmentDialog';
import {ArRefreshBtn} from '../../components/arRefresh/ArRefresh';
import {AttachmentTypeEnum, ChartsOfAccountType, DatTypes, DepositAmountType} from '../../model/constants/Constants';
import {IQuoteSettings} from '../../model/quotes/quotes';
import useQuotesAPI from '../../services/useQuotesAPI';
import {Toast} from '../../utils/Toast';
import LoadingOverlay from '../../components/ui/LoadingOverlay';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import AppContext from '../../context/app/appContext';
import {useAttachmentsAPI} from '../../services/useAttachmentsAPI';
import AttachmentDefaultView from '../../components/quote-management/AttachmentDefaultView';
import PreviewAttachment from './PreviewAttachment';
import {AttachmentType} from '../../model/FileDetails';
import {IntegrationContext} from '../../context/IntegrationContext';

interface RadioListItemProps {
  id: string;
  name: string;
  label: string;
  value: string | number;
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: IQuoteSettings;
  touched: Partial<Record<keyof IQuoteSettings, boolean>>;
  errors: Partial<Record<keyof IQuoteSettings, string>>;
}

interface SectionProps {
  title: string;
  items: Omit<RadioListItemProps, 'handleChange' | 'values' | 'touched' | 'errors'>[];
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: IQuoteSettings;
  touched: Partial<Record<keyof IQuoteSettings, boolean>>;
  errors: Partial<Record<keyof IQuoteSettings, string>>;
}

const RadioListItem: React.FC<RadioListItemProps> = ({
  id,
  name,
  label,
  value,
  handleChange,
  values,
  touched,
  errors,
}) => {
  const {quoteDefaultDepositAmountType} = values;
  return (
    <div className="flex flex-col gap-2">
      <ListItem className="flex w-full items-center px-3 py-2">
        <Radio
          color="primary"
          name={name}
          id={id}
          ripple={false}
          className="hover:before:opacity-0"
          containerProps={{className: 'p-0'}}
          label={label}
          value={value}
          onChange={e => handleChange(e)}
          labelProps={{className: 'font-poppins font-medium text-black-800 ml-2.5'}}
          defaultChecked={values[name as keyof IQuoteSettings] === value}
        />
      </ListItem>
      {quoteDefaultDepositAmountType === DepositAmountType.Percentage && value === 'Percentage' && (
        <Input
          data-autoid={`txtPercentageValue${id}`}
          label="Add Value"
          type="number"
          name="quoteDefaultDepositAmount"
          placeholder=""
          containerProps={{className: 'ml-12 w-[126px] min-w-[126px]'}}
          InputProp={{
            endAdornment: <span className="text-primary">%</span>,
            startAdornment: <></>,
          }}
          adornmentContainerClass="h-full w-full"
          labelProps={{}}
          maxLength={3}
          onChange={handleChange}
          value={values.quoteDefaultDepositAmount}
          helperText={
            touched.quoteDefaultDepositAmount && errors.quoteDefaultDepositAmount
              ? errors.quoteDefaultDepositAmount
              : ''
          }
          error={Boolean(touched.quoteDefaultDepositAmount && errors.quoteDefaultDepositAmount)}
        />
      )}
    </div>
  );
};

const Section: React.FC<SectionProps> = ({title, items, handleChange, values, touched, errors}) => (
  <div>
    <Typography variant="h3">{title}</Typography>
    <List>
      {items.map((item, index) => (
        <RadioListItem
          key={index}
          {...item}
          handleChange={handleChange}
          values={values}
          touched={touched}
          errors={errors}
        />
      ))}
    </List>
  </div>
);

const validationSchema = Yup.object().shape({
  customerDepositAccountID: Yup.string().required('Liability account is required'),
  quoteDefaultDepositAmount: Yup.string().when('quoteDefaultDepositAmountType', {
    is: (value: string) => value === DepositAmountType.Percentage,
    then: Yup.string()
      .required('Required')
      .matches(/^(100|[1-9]?[0-9])$/, 'Enter valid percent'),
  }),
});

const validityPeriodItems = [
  {id: '7', name: 'quoteValidityPeriodDays', label: '7 days', value: 7},
  {id: '14', name: 'quoteValidityPeriodDays', label: '14 days', value: 14},
  {id: '21', name: 'quoteValidityPeriodDays', label: '21 days', value: 21},
  {id: '30', name: 'quoteValidityPeriodDays', label: '30 days', value: 30},
];

const depositAmountItems = [
  {id: 'percentage', name: 'quoteDefaultDepositAmountType', label: 'Percentage', value: DepositAmountType.Percentage},
  {id: 'flat-fee', name: 'quoteDefaultDepositAmountType', label: 'Flat Fee', value: DepositAmountType.FlatFee},
  {id: 'no-deposit', name: 'quoteDefaultDepositAmountType', label: 'No Deposit', value: DepositAmountType.NoDeposit},
];

export const CustomisationSettingsForm = () => {
  const [liabilityAccounts, setLiabilityAccounts] = useState<IAccounts[]>([]);
  const [addedFileToUpload, setAddedFileToUpload] = useState<FileType | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [quoteSettings, setQuoteSettings] = useState<IQuoteSettings>({
    quoteValidityPeriodDays: 14,
    quoteDefaultDepositAmountType: DepositAmountType.Percentage,
    quoteDefaultDepositAmount: '',
    customerDepositAccountID: '',
  });
  const {uploadAttachments, deleteAttachment} = useAttachmentsAPI();
  const [previewImage, setPreviewImage] = React.useState({} as {uri: string; show: boolean; type: string});

  const {getCreditlineAccounts} = useAccountAPI();
  const {saveQuoteSettings, getQuotesSettings} = useQuotesAPI();
  const {isQuotesSettingsSaved, setIsQuotesSettingsSaved} = useContext(AppContext);
  const {isMYOBAdaptor} = useContext(IntegrationContext);

  const getChartAccounts = async () => {
    const data: IAccounts[] = await getCreditlineAccounts({
      Types: isMYOBAdaptor ? ChartsOfAccountType.CreditCard : ChartsOfAccountType.CurrentLiability,
    });

    setLiabilityAccounts(data);
  };

  const deleteFile = () => {
    setAddedFileToUpload(null);
  };

  const handleFileChange = (files: FileList) => {
    if (files && files.length > 0) {
      const newFile: FileType = {
        file: files[0],
        uri: URL.createObjectURL(files[0]),
      };
      setAddedFileToUpload(newFile);
    }
  };

  const handleSave = async (values: IQuoteSettings) => {
    let formData = null;
    if (addedFileToUpload != null) {
      formData = new FormData();
      formData.append('Files', addedFileToUpload.file, addedFileToUpload.file.name);
      const attachments = [
        {
          Caption: addedFileToUpload?.file.name,
          Note: '',
          Action: 0,
        },
      ];
      const jsonRequest = {
        Attachments: attachments || [],
      };
      formData.append('AttachmentType', JSON.stringify(AttachmentTypeEnum.TermsAndConditions));
      formData.append('JsonRequest', JSON.stringify(jsonRequest));
    }
    const payload = _.cloneDeep(values);
    if (payload.quoteDefaultDepositAmountType !== DepositAmountType.Percentage) {
      payload.quoteDefaultDepositAmount = '0';
    } else {
      const percent = parseFloat(payload.quoteDefaultDepositAmount || '0') / 100 || 0;
      payload.quoteDefaultDepositAmount = percent.toString();
    }
    await Promise.all([
      formData != null && uploadAttachments(formData, {'content-type': 'multipart/form-data'}),
      saveQuoteSettings(payload),
    ]);
    if (!isQuotesSettingsSaved) setIsQuotesSettingsSaved(true);
    setIsLoading(true);
    fetchQuoteSettings();
    Toast.info('Setting saved successfully.');
  };

  const fetchTermsConditions = async () => {
    try {
      const response = await fetch(quoteSettings?.quoteTermsMediaFileURL!);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob(); // Convert the response to a Blob
      const fileName = 'TermsNConditions.pdf';
      const file = new File([blob], fileName, {
        type: AttachmentType.pdf,
      });

      const reader = new FileReader();
      reader.onload = () => {
        // This will be the data URI
        setQuoteSettings({...quoteSettings, quoteTermsMediaFileURI: reader.result as string});
      };

      reader.readAsDataURL(file); // Convert Blob to Data URL
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const fetchQuoteSettings = async () => {
    const results = await Promise.all([
      getQuotesSettings(),
      getCreditlineAccounts({
        Types: isMYOBAdaptor ? ChartsOfAccountType.CreditCard : ChartsOfAccountType.CurrentLiability,
      }),
    ]);
    if (results?.[0] != null) {
      if (results?.[0]?.quoteDefaultDepositAmountType === DepositAmountType.Percentage) {
        results[0].quoteDefaultDepositAmount = (
          parseFloat(results?.[0].quoteDefaultDepositAmount || '0') * 100
        ).toString();
      }
      setQuoteSettings(results?.[0]);
      setLiabilityAccounts(results[1] || []);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (quoteSettings?.quoteTermsMediaFileURL && !quoteSettings?.quoteTermsMediaFileURI) fetchTermsConditions();
  }, [quoteSettings]);

  useEffect(() => {
    fetchQuoteSettings();
  }, []);

  const handleDeleteAttachment = async () => {
    if (quoteSettings?.attachmentGuid) {
      await deleteAttachment(AttachmentTypeEnum?.TermsAndConditions, quoteSettings?.attachmentGuid);
      setQuoteSettings({...quoteSettings, quoteTermsMediaFileID: undefined, quoteTermsMediaFileURL: undefined});
    }
  };

  return (
    <div className="flex h-full w-full justify-center">
      {isLoading ? (
        <LoadingIndicator isLoading={true} size="md" />
      ) : (
        <Formik
          initialValues={quoteSettings}
          validationSchema={validationSchema}
          onSubmit={handleSave}
          enableReinitialize
        >
          {({handleChange, values, touched, errors, handleSubmit, setFieldValue, isSubmitting}) => {
            return (
              <LoadingOverlay isLoading={isSubmitting} overlayBgColor={'rgba(0, 0, 0, 0.09)'}>
                <Form onSubmit={handleSubmit}>
                  <div className="absolute right-0 mt-[-100px] flex justify-end">
                    <Button type="submit" data-autoid="btnSave" loading={isSubmitting}>
                      Save
                    </Button>
                  </div>
                  <div className="h-[calc(100vh-210px)] overflow-y-auto">
                    <div className="ml-3 flex flex-col gap-4 font-poppins text-black-800">
                      <Section
                        title="Set a default validity period for quotes"
                        items={validityPeriodItems}
                        handleChange={handleChange}
                        values={values}
                        touched={touched}
                        errors={errors}
                      />
                      <Section
                        title="Set a default for the deposit amount"
                        items={depositAmountItems}
                        handleChange={handleChange}
                        values={values}
                        touched={touched}
                        errors={errors}
                      />
                      <div>
                        <Typography variant="h3" className="text-black-800">
                          Upload your own Terms and Conditions
                        </Typography>
                        <Typography variant="small" className="text-black-800">
                          This will be attached to the quote package and must be agreed upon by the customer before
                          accepting the quote.
                        </Typography>

                        {!addedFileToUpload && !quoteSettings.quoteTermsMediaFileID ? (
                          <Upload
                            data-autoid="uploadAttachment"
                            label="Drop any additional documents here or click to upload"
                            className="my-6 h-full min-h-[114px] max-w-[477px] text-sm font-normal text-black-800"
                            accept=".pdf"
                            onChange={handleFileChange}
                          />
                        ) : quoteSettings.quoteTermsMediaFileID && quoteSettings.quoteTermsMediaFileURL ? (
                          <AttachmentDefaultView
                            key={quoteSettings.quoteTermsMediaFileID}
                            uri={quoteSettings.quoteTermsMediaFileURL}
                            name={'TermsNConditions.pdf'}
                            caption={'TermsNConditions.pdf'}
                            containerClassName={'w-[12%] mt-6 flex-wrap justify-start'}
                            handleVisibility={() =>
                              setPreviewImage({
                                uri: quoteSettings.quoteTermsMediaFileURI!,
                                show: true,
                                type: 'Document',
                              })
                            }
                            onDelete={handleDeleteAttachment}
                            isEditAble
                          />
                        ) : (
                          addedFileToUpload && (
                            <AttachmentDefaultView
                              isEditAble
                              caption={addedFileToUpload.file.name}
                              name={addedFileToUpload.file.name}
                              uri={addedFileToUpload.uri}
                              containerClassName={'w-[12%] mt-6 flex-wrap justify-start'}
                              onDelete={deleteFile}
                            />
                          )
                        )}
                      </div>
                    </div>
                    <div className="mb-10 ml-3 mt-5 flex flex-col gap-4">
                      <div>
                        <Typography variant="h3" className="text-lg font-medium text-black-800">
                          Set up liability account for the prepayment
                        </Typography>
                      </div>
                      <div className="flex gap-6">
                        <Typography
                          variant="small"
                          className="flex items-center justify-center font-normal text-black-800"
                        >
                          Select liability account(deposit value):
                        </Typography>
                        <div className="flex flex-row items-center">
                          <div className={`mr-4 flex items-center`}>
                            <Select
                              name="customerDepositAccountID"
                              size="lg"
                              key={liabilityAccounts.length}
                              variant="outlined"
                              label={
                                touched.customerDepositAccountID && Boolean(errors.customerDepositAccountID)
                                  ? 'Select Account (required)'
                                  : 'Select Account'
                              }
                              value={values?.customerDepositAccountID?.toString()}
                              onChange={(value: string) => {
                                setFieldValue('customerDepositAccountID', value);
                              }}
                              className="w-[224px]"
                              error={touched.customerDepositAccountID && Boolean(errors.customerDepositAccountID)}
                            >
                              {liabilityAccounts.length ? (
                                liabilityAccounts?.map((options, index) => {
                                  return (
                                    <Option
                                      value={options?.ID?.toString()}
                                      key={index}
                                    >{`${options.Code} ${options.Name}`}</Option>
                                  );
                                })
                              ) : (
                                <Option value={'0'} key={0} disabled>
                                  No Records Found
                                </Option>
                              )}
                            </Select>
                          </div>
                          <ArRefreshBtn
                            handleRefreshData={getChartAccounts}
                            datTypeId={DatTypes.Accounts}
                            title={'Refresh Data'}
                            showOnlyRefreshIcon
                            height="18px"
                            width="18px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </LoadingOverlay>
            );
          }}
        </Formik>
      )}
      {previewImage.show && (
        <PreviewAttachment
          type={previewImage.type}
          imageUrl={previewImage.uri}
          setShow={value =>
            setPreviewImage({
              uri: '',
              show: value,
              type: '',
            })
          }
        />
      )}
    </div>
  );
};
