import React, {FC, useMemo, useState} from 'react';
import {AlertDialogSlideV2, IDialogActionV2} from './AlertDialogSlideV2';
import QuoteAttachment from '../../screens/quote-management/QuoteAttachment';
import {useAttachmentsAPI} from '../../services/useAttachmentsAPI';
import {AttachmentTypeEnum} from '../../model/constants/Constants';
import {IQuoteAttachment} from '../../model/quotes/quotes';
import {Toast} from '../../utils/Toast';
import {LoadingOverlayV1} from '../ui/LoadingOverlayV1';

type QuoteAttachmentDialogProps = {
  loading?: boolean;
  quoteID?: number;
  fetchAttachments?: () => void;
  uploadedAttachments?: IQuoteAttachment[];
  handleOpenAndClose: React.Dispatch<React.SetStateAction<boolean>>;
};

export type FileType = {
  file: File;
  uri: string;
};

export const QuoteAttachmentDialog: FC<QuoteAttachmentDialogProps> = ({
  fetchAttachments,
  handleOpenAndClose,
  loading,
  quoteID,
  uploadedAttachments,
}) => {
  const [addedFilesToUpload, setAddedFilesToUpload] = useState<FileType[]>([]);
  const [deleteGUIDs, setDeleteGUIDs] = useState<string[]>([]);

  const {uploadAttachments, deleteAttachments, isLoading} = useAttachmentsAPI();

  loading = loading || isLoading;

  const deleteAttachment = (guid: string) => {
    setDeleteGUIDs(prev => [...prev, guid]);
  };

  const deleteFile = (index: number) => {
    let data = [...addedFilesToUpload];
    if (index >= 0) {
      data.splice(index, 1);
      setAddedFilesToUpload(data);
    }
  };

  const onSubmit = async () => {
    try {
      if (!quoteID) return;

      if (deleteGUIDs.length > 0) {
        try {
          await deleteAttachments(AttachmentTypeEnum.Quotes, deleteGUIDs);
        } catch {
          Toast.error('Failed to delete attachments');
        }
      }
      if (addedFilesToUpload?.length) {
        const formData = new FormData();

        addedFilesToUpload?.forEach(attachment => {
          formData.append('Files', attachment.file, attachment.file.name);
        });

        const attachments = addedFilesToUpload?.map((n, i) => {
          return {
            Caption: addedFilesToUpload?.[i]?.file.name,
            Note: '',
            Action: 0,
          };
        });

        const jsonRequest = {
          QuoteID: quoteID,
          Attachments: attachments || [],
        };

        formData.append('AttachmentType', JSON.stringify(AttachmentTypeEnum.Quotes));
        formData.append('JsonRequest', JSON.stringify(jsonRequest));
        await uploadAttachments(formData, {'content-type': 'multipart/form-data'});
      }
      handleOpenAndClose(false);
      fetchAttachments?.();
    } catch {
      Toast.error('Failed to upload attachments');
    }finally {
      setDeleteGUIDs([]);
    }
  };

  const actions: IDialogActionV2[] = [
    {
      label: 'Cancel',
      variant: 'outlined',
      action: () => handleOpenAndClose(false),
    },
    {
      label: 'Save & Close',
      loading: loading,
      disabled: loading,
      action: onSubmit,
    },
  ];

  const filterUploadedAttachments = useMemo(() => {
    return uploadedAttachments?.filter(att => !deleteGUIDs.includes(att.attachmentGuid));
  }, [uploadedAttachments, deleteGUIDs]);

  return (
    <AlertDialogSlideV2
      title={'Add attachment'}
      headingTextSize="h2"
      data-autoid={'dlgAddAttachment'}
      dialogActionsAlignment={'justify-between'}
      dialogBodyClassess="flex flex-col first:gap-y-5  gap-y-2 items-center h-[330px] pt-7 overflow-y-auto max-h-[18rem]"
      actions={actions}
    >
      <LoadingOverlayV1 isLoading={loading}>
        <QuoteAttachment
          addedFilesToUpload={addedFilesToUpload}
          setAddedFilesToUpload={setAddedFilesToUpload}
          deleteFile={deleteFile}
          isUploading={loading}
          disabled={loading}
          uploadedAttachments={filterUploadedAttachments}
          deleteQuoteAttachment={deleteAttachment}
          isEditAble={true}
        />
      </LoadingOverlayV1>
    </AlertDialogSlideV2>
  );
};
