import React, {FC, useMemo, useState} from 'react';
import {useFormikContext} from 'formik';

import QuoteAttachment from '../../quote-management/QuoteAttachment';
import {IServiceJob, ServiceJobStatus} from '../../../model/service-management/serviceJob';
import {useAttachmentsAPI} from '../../../services/useAttachmentsAPI';
import {AttachmentTypeEnum} from '../../../model/constants/Constants';
import {useServiceContext} from '../../../context/serviceManagement/ServiceContext';
import {FileType} from '../../../components/dialog/QuoteAttachmentDialog';
import {IQuoteAttachment} from '../../../model/quotes/quotes';

type ServiceAttachmentTabProps = {
  addedFilesToUpload: FileType[];
  setAddedFilesToUpload: React.Dispatch<React.SetStateAction<FileType[]>>;
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>;
};

const ServiceAttachmentTab: FC<ServiceAttachmentTabProps> = props => {
  const {addedFilesToUpload, setAddedFilesToUpload, setIsSaving} = props;
  const {values, setFieldValue} = useFormikContext<IServiceJob>();
  const {deleteAttachment, isLoading} = useAttachmentsAPI();
  const {serviceJob} = useServiceContext();
  const [invalidFiles, setInvalidFiles] = useState<FileType[]>([]);

  const deleteServiceAttachment = async (guid: string) => {
    setIsSaving(true);
    try {
      const res = await deleteAttachment(AttachmentTypeEnum.ServiceJob, guid);
      if (res) {
        setFieldValue(
          'attachments',
          values?.attachments.filter(a => a.attachmentGuid !== guid),
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  const deleteFile = (index: number, isInvalid: boolean) => {
    if (isInvalid) {
      let invalidData = [...invalidFiles];
      if (index >= 0) {
        invalidData.splice(index, 1);
        setInvalidFiles(invalidData);
      }
    } else {
      let data = [...addedFilesToUpload];
      if (index >= 0) {
        data.splice(index, 1);
        setAddedFilesToUpload(data);
      }
    }
  };

  const uploadedAttachments = useMemo(
    () =>
      values?.attachments.filter((attachment: IQuoteAttachment) => !attachment?.isCreatedByServiceTechnician) ??
      ([] as IQuoteAttachment[]),
    [values?.attachments],
  );

  return (
    <QuoteAttachment
      addedFilesToUpload={addedFilesToUpload}
      invalidFiles={invalidFiles}
      setAddedFilesToUpload={setAddedFilesToUpload}
      setInvalidFiles={setInvalidFiles}
      deleteFile={deleteFile}
      uploadedAttachments={uploadedAttachments}
      deleteQuoteAttachment={deleteServiceAttachment}
      disabled={isLoading}
      isEditAble={serviceJob?.status !== ServiceJobStatus.Closed && serviceJob?.status !== ServiceJobStatus.Cancelled}
      isServiceJobView
    />
  );
};

export default ServiceAttachmentTab;
