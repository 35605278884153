import React, {FC} from 'react';
import {AlertDialogSlideV2, IDialogActionV2} from './AlertDialogSlideV2';
import QuoteAttachment from '../../screens/quote-management/QuoteAttachment';
import {IQuoteAttachment} from '../../model/quotes/quotes';
import {LoadingOverlayV1} from '../ui/LoadingOverlayV1';

type QuoteAttachmentPreviewDialogProps = {
  loading: boolean;
  handleOpenAndClose: React.Dispatch<React.SetStateAction<boolean>>;
  uploadedAttachments?: IQuoteAttachment[];
};


export const QuoteAttachmentPreviewDialog: FC<QuoteAttachmentPreviewDialogProps> = ({
  handleOpenAndClose,
  uploadedAttachments,
  loading,
}) => {
  const actions: IDialogActionV2[] = [
    {
      label: 'Close',
      loading: loading,
      disabled: loading,
      action: () => handleOpenAndClose(false),
    },
  ];

  return (
    <AlertDialogSlideV2
      title={'View attachment'}
      headingTextSize="h2"
      data-autoid={'dlgViewAttachment'}
      dialogActionsAlignment={'justify-center'}
      dialogBodyClassess="flex flex-col first:gap-y-5  gap-y-2 items-center h-[330px] pt-7 overflow-y-auto max-h-[18rem]"
      actions={actions}
    >
      <LoadingOverlayV1 isLoading={loading}>
        <QuoteAttachment
          isUploading={loading}
          disabled={loading}
          uploadedAttachments={uploadedAttachments}
          isEditAble={false}
        />
      </LoadingOverlayV1>
    </AlertDialogSlideV2>
  );
};
