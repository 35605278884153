import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {Button, Checkbox, Typography} from 'spenda-ui-react';
import {Formik, FormikProps, Form} from 'formik';
import * as Yup from 'yup';
import {isEmpty} from 'lodash';

import {IActionResults} from '../../model/ActionResults';
import {Inventory, IStagingCatalogue} from '../../model/inventory/Inventory';
import {InventorySOH} from '../../model/inventory/InventorySOH';
import {AUTH_INVENTORY_PRODUCT_EDIT, AUTH_INVENTORY_PRODUCTS_LIST} from '../../routes/InventoryRoutes';
import {AddInventory, AddInventoryValues} from '../../components/form/InventoryForm';
import {Layout} from '../../components/layout/Layout';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {useInventoryAPI} from '../../services/useInventoryAPI';

import {Toast} from '../../utils/Toast';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import AppContext from '../../context/app/appContext';
import {APFooter} from '../../components/accountsPayableOnboarding/APFooter';
import {useStagedTransactionsAPI} from '../../services/useStagedTransactionsAPI';
import {Actions, AttachmentTypeEnum, ProductWarrantyPeriod} from '../../model/constants/Constants';
import {IPostingInventoryRequest} from '../../model/requires-attention/StagedTransactions';
import backButton from '../../assets/png/back-button.png';
import {AlertDialog, AlertDialogSlideV2} from '../../components/dialog/AlertDialogSlideV2';
import {useAttachmentsAPI} from '../../services/useAttachmentsAPI';
import {IBrand} from '../../model/inventory/Brand';
import {InventoryWarrantyMediaFileDetails} from '../../model/inventory/Inventory';
import {IStagingInventory} from '../../context/inventory-context/InventoryContext';

const validationSchema = Yup.object({
  ShortDescription: Yup.string()
    .required('Product Name is required.')
    .max(50, 'Product Name cannot be longer than 50 characters.'),
  InventoryCode: Yup.string()
    .required('Product Code is required.')
    .max(50, 'Product Code cannot be longer than 50 characters.'),
  UoM: Yup.string().required('Product UoM is required.'),
  UoMDescription: Yup.string().required('Product UoM Description is required.'),
  Type: Yup.string().required('Product Type Description is required.'),
  WarrantyPeriod: Yup.string()
    .ensure()
    .when(['WarrantyPeriodType'], {
      is: (WarrantyPeriodType: string) => {
        if (WarrantyPeriodType == null || WarrantyPeriodType == '') return false;
        return (
          WarrantyPeriodType !== ProductWarrantyPeriod.LIFETIME && WarrantyPeriodType !== ProductWarrantyPeriod.NONE
        );
      },
      then: Yup.string().required('Period is required'),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  MinMaxLevels: Yup.array().of(
    Yup.object().shape({
      MinSOHQuantity: Yup.number()
        .nullable()
        .test('min_max_check', 'Enter positive integer', function (val) {
          const MaxSOHQuantity = this.parent.MaxSOHQuantity;
          if (val) {
            if (!Number.isInteger(val) || val < 0) {
              return false;
            } else if (Number.isInteger(val) && Number.isInteger(MaxSOHQuantity) && MaxSOHQuantity <= val) {
              return this.createError({message: 'Min should be less than Max!'});
            }
          }
          return true;
        }),
      MaxSOHQuantity: Yup.number()
        .nullable()
        .test('min_max_check', 'Enter positive integer', function (val) {
          const MinSOHQuantity = this.parent.MinSOHQuantity;
          if (val) {
            if (!Number.isInteger(val) || val < 0) {
              return false;
            } else if (Number.isInteger(val) && Number.isInteger(MinSOHQuantity) && MinSOHQuantity >= val) {
              return this.createError({message: 'Max should be greater than Min!'});
            }
          }
          return true;
        }),
    }),
  ),
});

interface IAddProductProps {
  context?: 'AP' | 'PM';
  handleClose?: () => void;
  handleCreateAction?: () => void;
  supplierID?: number;
  inventoryCode?: string;
  action?: Actions;
  requestItems?: IPostingInventoryRequest[];
  isMultiplePostingSelected?: boolean;
  selectedRuleID?: number;
  isInDialog?: boolean;
  hideActionButtons?: boolean;
  stagingInventoryData?: IStagingInventory;
  childVariantID?: number;
  isInternalTab?: boolean;
  setOpenChildItemEdit?: (value: boolean) => void;
  handleEditMasterPrice?: () => void;
}

export const AddProduct = (props: IAddProductProps) => {
  const {
    context,
    handleClose,
    action,
    handleCreateAction,
    supplierID,
    inventoryCode,
    requestItems,
    isMultiplePostingSelected,
    selectedRuleID,
    isInDialog,
    hideActionButtons,
    stagingInventoryData,
    childVariantID,
    setOpenChildItemEdit,
    isInternalTab,
    handleEditMasterPrice,
  } = props;
  const isInAPContext = context === 'AP';

  const history = useHistory();
  const {productID} = useParams<{productID?: string | undefined}>();
  const query = new URLSearchParams(useLocation().search);
  const selectedTab = query.get('tab') || undefined;

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [openUnlinkDialog, setOpenUnlinkDialog] = useState<boolean>(false);

  const {tenantInfo, setTenantInfo} = useContext(AppContext);

  const {createInventory, getInventoryById, getInventorySOH, inventoryCodeUnique, isLoading, updateInventory} =
    useInventoryAPI();

  const newProduct: AddInventoryValues = {
    ShortDescription: '',
    InventoryCode: '',
    InventoryClassID: '',
    InventoryClassName: '',
    SKU: '',
    UoM: '',
    UoMDescription: '',
    Barcode: '',
    Type: '',
    Description: '',
    ExpenseAccount: '',
    RevenueAccount: '',
    HTMLDescription: '',
    IsSold: false,
    SellPriceEx: 0,
    RRP: 0,
    StandardSellPriceEx: 0,
    StandardSellPriceInc: 0,
    LastBuyPriceEx: 0,
    IsOrderTaxExempt: false,
    IsPublished: false,
    IsPurchased: false,
    IsSOHTracked: false,
    IsVariantMaster: false,
    CostPriceEx: 0,
    IsPhysical: false,
    Width_m: '',
    Length_m: '',
    Depth_m: '',
    Weight_kg: '',
    Volume: '',
    IsActive: true,
    WarrantyMediaFiles: [] as InventoryWarrantyMediaFileDetails[],
    ChildIsSold: false,
    ChildIsPurchased: false,
    ChildIsPhysical: false,
    ChildIsSOHTracked: false,
  } as AddInventoryValues;

  const [inventoryToEdit, setInventoryToEdit] = React.useState<AddInventoryValues>(newProduct);
  const [inventorySOH, setInventorySOH] = React.useState<InventorySOH | undefined>(undefined);
  const [alert, setAlert] = useState({
    open: false,
    redirect: false,
  });

  const {T2TPhase280729, QuoteV289367} = useFeatureFlags().tenantOwned();
  const {postInventoryPostingRule} = useStagedTransactionsAPI();
  const {uploadAttachments, deleteAttachments} = useAttachmentsAPI();

  const handleConfirmUnlink = async () => {
    if (productID) {
      const payload = {
        ...inventoryToEdit,
        VariantMasterInventoryID: -1,
        IsVariantMaster: false,
      };
      try {
        await createInventory(payload);
        Toast.info('Variant unlinked successfully');
        setOpenUnlinkDialog(false);
        handleClose?.();
      } catch (error) {
        Toast.error('Failed to unlink variant');
      }
    }
  };

  async function fetchAndPrepareInventory(
    productID: string,
    _QuoteV289367?: boolean,
  ): Promise<AddInventoryValues | undefined> {
    const res: Inventory = await getInventoryById(productID);

    if (!res) return;

    const _inventory: AddInventoryValues = {
      ID: res.ID,
      ShortDescription: res.ShortDescription || '',
      InventoryCode: res.InventoryCode || '',
      InventoryClassID: res.InventoryClassID,
      InventoryClassName: res.InventoryClassName || '',
      SKU: res.SKU || '',
      UoM: res.UoM || '',
      Brand: res.Brand as IBrand,
      Categories: res.Categories || [],
      UoMDescription: res.UoMDescription || '',
      Barcode: res.Barcode || '',
      Type: res.Type || '',
      Description: res.Description || '',
      ExpenseAccount: res.ExpenseAccount || '',
      RevenueAccount: res.RevenueAccount || '',
      HTMLDescription: res.HTMLDescription || '',
      IsSold: res.IsSold || false,
      SellPriceEx: res.SellPriceEx ?? 0,
      LastBuyPriceEx: res.LastBuyPriceEx || '',
      RRP: res.RRP ?? 0,
      StandardSellPriceEx: res.StandardSellPriceEx ?? 0,
      StandardSellPriceInc: res.StandardSellPriceInc ?? 0,
      IsOrderTaxExempt: res.IsOrderTaxExempt || false,
      IsPublished: res.IsPublished || false,
      IsPurchased: res.IsPurchased || false,
      IsApproved: res.IsApproved || false,
      AssetAccount: res.AssetAccount || '',
      IsSOHTracked: res.IsSOHTracked || false,
      IsVariantMaster: res.IsVariantMaster || false,
      CostPriceEx: res.CostPriceEx ?? 0,
      IsPhysical: res.IsPhysical || false,
      IsActive: res.IsActive || false,
      DefaultSupplierName: res.DefaultSupplierName || '',
      ChildIsSold: res.ChildIsSold ?? false,
      ChildIsPurchased: res.ChildIsPurchased ?? false,
      ChildIsPhysical: res.ChildIsPhysical ?? false,
      ChildIsSOHTracked: res.ChildIsSOHTracked ?? false,
      ChildVariants: res.ChildVariants || [],
      ...res,
    } as AddInventoryValues;
    if (Array.isArray(res.MinMaxLevels)) {
      _inventory.MinMaxLevels = res.MinMaxLevels.sort((a, b) => a.WarehouseID - b.WarehouseID);
    }
    setInventoryToEdit(_inventory);
    return _inventory;
  }

  useEffect(() => {
    if (childVariantID) {
      (async () => {
        const childInventory = await fetchAndPrepareInventory(childVariantID.toString());
        if (childInventory) {
          setInventoryToEdit(childInventory);
        }
      })();
    } else if (productID) {
      (async () => {
        const inventory = await fetchAndPrepareInventory(productID, QuoteV289367);
        if (inventory) {
          setInventoryToEdit(inventory);
        }
      })();
      getInventorySOH(productID).then((res: InventorySOH) => {
        if (res) {
          res.StockOnHand = res.StockOnHand.sort((a, b) => a.WarehouseID - b.WarehouseID);
          setInventorySOH(res);
        }
      });
    } else {
      const _inventory = newProduct;
      _inventory.UoM = 'EA';
      _inventory.UoMDescription = 'Each';
      _inventory.Type = 'Inventory';
      setInventoryToEdit(_inventory);
    }
  }, [childVariantID, productID]);

  useEffect(() => {
    const prepareStagingInventory = () => {
      const _inventory: AddInventoryValues = {
        ShortDescription: (stagingInventoryData as IStagingCatalogue)?.shortDescription || '',
        InventoryCode: (stagingInventoryData as IStagingCatalogue)?.code || '',
        InventoryClassName: (stagingInventoryData as IStagingCatalogue)?.className || '',
        SKU: (stagingInventoryData as IStagingCatalogue)?.sku || '',
        UoM: (stagingInventoryData as IStagingCatalogue)?.uoM || '',
        UoMDescription: (stagingInventoryData as IStagingCatalogue)?.uoMDescription || '',
        Barcode: (stagingInventoryData as IStagingCatalogue)?.barcode || '',
        Description: (stagingInventoryData as IStagingCatalogue)?.description || '',
        ExpenseAccount: (stagingInventoryData as IStagingCatalogue)?.expenseAccount || '',
        RevenueAccount: (stagingInventoryData as IStagingCatalogue)?.revenueAccount || '',
        HTMLDescription: (stagingInventoryData as IStagingCatalogue)?.description || '',
        IsSold: (stagingInventoryData as IStagingCatalogue)?.isSold || false,
        SellPriceEx: (stagingInventoryData as IStagingCatalogue)?.sellPriceEx ?? 0,
        RRP: (stagingInventoryData as IStagingCatalogue)?.rrp ?? 0,
        IsOrderTaxExempt: (stagingInventoryData as IStagingCatalogue)?.isOrderTaxExempt || false,
        IsPublished: (stagingInventoryData as IStagingCatalogue)?.isPublished || false,
        IsPurchased: (stagingInventoryData as IStagingCatalogue)?.isPurchased || false,
        AssetAccount: (stagingInventoryData as IStagingCatalogue)?.assetAccount || '',
        IsSOHTracked: (stagingInventoryData as IStagingCatalogue)?.isSOHTracked || false,
        CostPriceEx: (stagingInventoryData as IStagingCatalogue)?.costPriceEx ?? 0,
        IsPhysical: (stagingInventoryData as IStagingCatalogue)?.isPhysical || false,
        IsActive: true,
        DefaultSupplierName: (stagingInventoryData as IStagingCatalogue)?.supplierName || '',
        Width_m: (stagingInventoryData as IStagingCatalogue)?.width_m,
        Length_m: (stagingInventoryData as IStagingCatalogue)?.length_m,
        Depth_m: (stagingInventoryData as IStagingCatalogue)?.depth_m,
        Weight_kg: (stagingInventoryData as IStagingCatalogue)?.weight_kg,
        ChildIsSold: (stagingInventoryData as IStagingCatalogue)?.isSold,
        WarrantyPeriodType: (stagingInventoryData as IStagingCatalogue)?.warrantyPeriodType,
        WarrantyPeriod: (stagingInventoryData as IStagingCatalogue)?.warrantyPeriod,
        WarrantyDetails: (stagingInventoryData as IStagingCatalogue)?.warrantyDetails,
      } as AddInventoryValues;
      setInventoryToEdit(_inventory);
    };
    QuoteV289367 &&
      !isEmpty(stagingInventoryData) &&
      stagingInventoryData.type === 'catalogue' &&
      prepareStagingInventory();
  }, [stagingInventoryData]);

  const updateWarranty = async (inventoryID: string, warrantyMediaFiles: AddInventoryValues['WarrantyMediaFiles']) => {
    // construct an array of GUID's to delete
    const warrantyMediaFileGUIDs = [] as string[];
    warrantyMediaFiles?.forEach(file => {
      if (file.WarrantyMediaFileGUID && file.isDeleted) {
        warrantyMediaFileGUIDs.push(file.WarrantyMediaFileGUID);
      }
    });
    if (warrantyMediaFileGUIDs.length > 0) {
      await deleteAttachments(AttachmentTypeEnum.InventoryWarranty, warrantyMediaFileGUIDs);
    }

    // construct for upload
    const addWarrantyDocuments = warrantyMediaFiles?.filter(file => file.file);

    // upload warranty file
    if (addWarrantyDocuments && addWarrantyDocuments?.length > 0) {
      const formData = new FormData();

      addWarrantyDocuments.forEach(addWarrantyDocument => {
        formData.append('Files', addWarrantyDocument.file!, addWarrantyDocument.WarrantMediaFileName);
      });
      const attachments = addWarrantyDocuments.map(addWarrantyDocument => {
        return {
          Caption: addWarrantyDocument.WarrantMediaFileName,
          Note: '',
          Action: 0,
        };
      });

      const jsonRequest = {
        InventoryID: inventoryID,
        Attachments: attachments || [],
      };

      formData.append('AttachmentType', JSON.stringify(AttachmentTypeEnum.InventoryWarranty));
      formData.append('JsonRequest', JSON.stringify(jsonRequest));

      await uploadAttachments(formData, {'content-type': 'multipart/form-data'});
    }
  };

  const handleSave = async (values: AddInventoryValues, IsRedirect: boolean, validateAccount = true) => {
    if (inventoryToEdit.Brand && !values.Brand) {
      values.Brand = {id: 0} as any;
    }
    if (inventoryToEdit.InventoryClassID && !values.InventoryClassID) {
      values.InventoryClassID = -1 as any;
    }

    if (
      validateAccount &&
      values.IsVariantMaster &&
      values.ChildVariants &&
      values.ChildVariants.length > 0 &&
      (inventoryToEdit.RevenueAccount !== values.RevenueAccount ||
        inventoryToEdit.ExpenseAccount !== values.ExpenseAccount)
    ) {
      setAlert({open: true, redirect: IsRedirect});
      return;
    }

    let inventoryCodeRes = {Value: 0};
    if (!productID || inventoryToEdit.InventoryCode != values.InventoryCode) {
      inventoryCodeRes = await inventoryCodeUnique({
        InventoryCode: values.InventoryCode,
        InventoryID: values?.ID || undefined,
      });
    }

    if (productID) {
      if (inventoryCodeRes.Value > 0) {
        Toast.error(`Inventory code already exist`);
        return Promise.reject();
      }
      return updateInventory(values).then(async (res: IActionResults<Inventory>) => {
        if (res.IsSuccess) {
          if (T2TPhase280729) {
            if (
              tenantInfo?.TenantUserDetails?.DefaultGenericLineItemInventoryId === values?.ID &&
              values?.ExpenseAccount?.toString() !==
                tenantInfo?.TenantUserDetails?.DefaultGenericLineItemInventoryExpenseAccountCode?.toString()
            ) {
              //update app context
              const tenantUserDetails = tenantInfo?.TenantUserDetails;
              if (tenantInfo && tenantUserDetails) {
                tenantUserDetails.DefaultGenericLineItemInventoryExpenseAccountCode = values?.ExpenseAccount
                  ? parseInt(values?.ExpenseAccount)
                  : undefined;
                const info = {Modules: tenantInfo.Modules, TenantUserDetails: tenantUserDetails};
                setTenantInfo(info);
              }
            }
          }
          try {
            QuoteV289367 && (await updateWarranty(values?.ID, values.WarrantyMediaFiles));
          } catch (e) {
            console.error('error', e);
          }
          Toast.info(`Product '${values.ShortDescription}' updated`);
          if (isInAPContext) {
            handleClose?.();
          } else {
            if (IsRedirect) {
              history.push(AUTH_INVENTORY_PRODUCTS_LIST);
            } else {
              const inventory = await fetchAndPrepareInventory(productID, QuoteV289367);
              if (inventory) {
                setInventoryToEdit(inventory);
              }
            }
          }
        }
      });
    } else {
      if (inventoryCodeRes.Value > 0) {
        Toast.error(`Inventory code already exist.`);
        return Promise.reject();
      }
      const response: IActionResults<Inventory> = await createInventory(values);
      if (response.IsSuccess) {
        if (isInAPContext && T2TPhase280729) {
          try {
            if (!isMultiplePostingSelected) {
              await postInventoryPostingRule(supplierID!, [
                {
                  SourceSupplierID: supplierID!,
                  InventoryCode: inventoryCode!,
                  PostingInventoryID: response?.Value?.ID,
                  Action: action!,
                  ID: selectedRuleID,
                },
              ]);
            } else {
              const requestArr = requestItems?.map(item => {
                item.PostingInventoryID = response?.Value?.ID;
                return item;
              });
              requestArr && (await postInventoryPostingRule(supplierID!, requestArr));
            }
            handleCreateAction?.();
          } catch (error) {
            console.error('error', error);
          }
        }
        try {
          QuoteV289367 && (await updateWarranty(response?.Value?.ID, values.WarrantyMediaFiles));
        } catch (e) {
          console.error('error', e);
        }
        Toast.info(`Product '${values.ShortDescription}' created`);
        if (!isInAPContext && IsRedirect) {
          history.push(AUTH_INVENTORY_PRODUCTS_LIST);
        } else {
          history.push(AUTH_INVENTORY_PRODUCT_EDIT.replace(':productID', response?.Value?.ID.toString()));
        }
      }
    }
  };

  const onSubmit = async (values: AddInventoryValues): Promise<void> => {
    handleSave(values, true);
  };

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLFormElement>) => {
    if (keyEvent && keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  const handleArchiveProduct = (
    event: React.ChangeEvent<HTMLInputElement>,
    fProps: FormikProps<AddInventoryValues>,
  ) => {
    fProps.setFieldValue('IsActive', !event.target.checked);
  };

  const handleCloseClick = (props: FormikProps<AddInventoryValues>) => {
    if (props.dirty) {
      setOpenConfirmationDialog(true);
    } else if (isInAPContext) {
      handleClose?.();
    } else {
      history.push(AUTH_INVENTORY_PRODUCTS_LIST);
    }
  };

  const handleDiscard = () => {
    if (isInAPContext) {
      setOpenConfirmationDialog(false);
      handleClose?.();
    } else {
      history.push(AUTH_INVENTORY_PRODUCTS_LIST);
    }
  };

  const resetAlert = () => {
    setAlert({
      open: false,
      redirect: false,
    });
  };

  const _addProduct = (
    <div className={` relative flex h-full w-full flex-col rounded-lg bg-white`} id="sales-orders-list">
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={inventoryToEdit}
        onSubmit={onSubmit}
      >
        {props => (
          <Form
            onKeyDown={onKeyDown}
            onSubmit={props.handleSubmit}
            className={`relative flex h-full flex-col  ${isInDialog ? 'px-8' : 'px-2.5'}`}
          >
            <div
              className={`${
                isInAPContext
                  ? 'absolute right-0 top-0 mr-5 h-[44px] items-center justify-end'
                  : 'justify-between py-2.5'
              } flex w-full`}
            >
              {!isInDialog && (
                <div className="left-2.5 flex items-center">
                  <span
                    className="cursor-pointer"
                    data-autoid="btnBack"
                    onClick={() => {
                      history.push(AUTH_INVENTORY_PRODUCTS_LIST);
                    }}
                  >
                    <img
                      src={backButton}
                      alt="Back Button"
                      style={{width: '40px', height: '40px', margin: '0px 10px 0px 0px'}}
                      data-autoid={`imgBack`}
                    />
                  </span>
                  {!isInAPContext && (
                    <Typography className="flex items-center justify-center text-xl font-light text-black-800">
                      Product details
                    </Typography>
                  )}
                </div>
              )}
              <div className={`flex bg-white font-poppins font-medium ${isInDialog ? 'z-10 p-6 pt-10' : 'pr-2.5'}`}>
                <Checkbox
                  ripple={false}
                  label="Archive Product"
                  className="h-4 w-4 border-primary bg-white transition-all checked:border-primary checked:bg-primary hover:before:opacity-0 "
                  checked={!props.values.IsActive}
                  onChange={e => handleArchiveProduct(e, props)}
                />
              </div>
            </div>

            {!isInDialog && <div className="mb-2 h-1 w-full border-b border-b-[#ECECEC]" />}

            <AddInventory
              {...props}
              inventorySOH={inventorySOH}
              selectedTab={selectedTab}
              isInAPContext={isInAPContext}
              isInDialog={isInDialog}
              childVariantID={childVariantID}
              setOpenChildItemEdit={setOpenChildItemEdit}
              isInternalTab={isInternalTab}
              handleEditMasterPrice={handleEditMasterPrice}
            />
            {!hideActionButtons ? (
              isInAPContext ? (
                <div className="z-[999] mt-20 bg-white">
                  <APFooter
                    isCancelButton={true}
                    buttonLabel={'Done'}
                    onNextClick={() => props.isSubmitting}
                    isDisabled={props.isSubmitting || !props.dirty}
                    handleCancelClick={() => handleCloseClick(props)}
                    isT2TSupplierModule={true}
                    conditionallyClass={true}
                    APFooterClass="!bottom-[10px] !left-[10px]"
                    style={{width: 'calc(100% - 20px)'}}
                    showUnlinkVariantsButton={childVariantID ? true : false}
                    handleUnlinkVariants={() => setOpenUnlinkDialog(true)}
                  />
                </div>
              ) : (
                <div
                  className={`fixed bottom-4 left-[22px] z-[999] flex w-[calc(100%-44px)] items-center ${QuoteV289367 ? 'justify-between' : 'justify-end'}  rounded-lg bg-[#ECECEC]`}
                >
                  <Button
                    className="m-2.5 mr-2 bg-white"
                    variant="outlined"
                    onClick={() => handleCloseClick(props)}
                    data-autoid="btnClose"
                  >
                    Cancel
                  </Button>
                  <div>
                    <Button
                      className="m-2.5 ml-2"
                      disabled={props.isSubmitting || !props.dirty}
                      loading={props.isSubmitting}
                      type="button"
                      onClick={() => {
                        handleSave(props.values, false);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      className="m-2.5 ml-2"
                      disabled={props.isSubmitting || !props.dirty}
                      loading={props.isSubmitting}
                      data-autoid="btnSave"
                      type="submit"
                    >
                      Save & Close
                    </Button>
                  </div>
                </div>
              )
            ) : null}
            {alert.open && (
              <AlertDialog
                size="xs"
                actions={[
                  {
                    label: 'No',
                    action: resetAlert,
                    variant: 'outlined',
                  },
                  {
                    label: 'Yes',
                    action: () => {
                      resetAlert();
                      handleSave(props.values, alert.redirect, false);
                    },
                  },
                ]}
                title="Alert"
                headingTextSize="h2"
                contentClass="whitespace-pre-line text-sm"
                content={`Updating Account Code(s) for this Master Inventory will also update the same fields for all its Child Variants.\n Are you sure you want to proceed?`}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );

  return (
    <>
      {openConfirmationDialog && (
        <AlertDialogSlideV2
          id={isInAPContext ? 'discardPopup' : ''}
          headingTextSize="h2"
          size="md"
          title={'Discard Changes'}
          footer={
            <div className={`${isInAPContext ? '' : 'px-4'} flex w-full justify-between`}>
              <Button variant="outlined" data-autoid="btnCancel" onClick={() => setOpenConfirmationDialog(false)}>
                Cancel
              </Button>
              <Button data-autoid="btnDiscard" onClick={() => handleDiscard()}>
                Discard
              </Button>
            </div>
          }
          dialogClassess="!max-w-[497px] !min-w-[20%]"
        >
          <Typography className={`${isInAPContext ? 'py-12' : 'pb-6 pt-6'} text-center font-medium text-black`}>
            You have unsaved changes, are you sure you want to discard changes?
          </Typography>
        </AlertDialogSlideV2>
      )}

      {openUnlinkDialog && (
        <AlertDialogSlideV2
          id="unlinkVariantPopup"
          headingTextSize="h2"
          size="md"
          title={'Unlink Variant'}
          footer={
            <div className="flex w-full justify-between">
              <Button variant="outlined" data-autoid="btnCancel" onClick={() => setOpenUnlinkDialog(false)}>
                Cancel
              </Button>
              <Button data-autoid="btnUnlink" onClick={handleConfirmUnlink}>
                Unlink
              </Button>
            </div>
          }
          dialogClassess="!max-w-[497px] !min-w-[20%]"
        >
          <Typography variant="paragraph" className="px-10 py-8 text-center text-sm text-black">
            Are you sure you want to unlink this variant? If you remove this Child Variant from its Master, this Item
            will be removed from your list of Variants and converted to a standalone Inventory Item again.
          </Typography>
        </AlertDialogSlideV2>
      )}
      <div className={`relative h-full overflow-hidden bg-spenda-newbg ${isInAPContext ? 'rounded-xl' : ''}`}>
        {isInAPContext ? _addProduct : <Layout leftPanel={_addProduct} splitWidthType={4} />}
        <LoadingIndicator isLoading={isLoading} size="md" color="hsl(var(--primary))" />
      </div>
    </>
  );
};
